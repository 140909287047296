.search_container{
width: 80vw;
      height:10%;
      display:flex;
      justify-content:center;
      align-items:center;
      gap:10px
}

@media screen and (max-width: 766px) {
    .search_container{
        flex-direction: column;
        
    }
    .MuiButton-contained{
        width: 75%;
    }
  }